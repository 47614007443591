/* Service.css */

.service-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 20px;
}

.service-heading {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.service-card {
  transition: transform 0.3s ease-in-out;
}

.service-card:hover {
  transform: scale(1.05);
}

.service-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 0.5rem 0.5rem 0 0;
}

.service-text {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service-button {
  margin-top: 1rem;
  align-self: flex-start;
}

.service-button-1 {
  background-color: #5a67d8; /* Indigo */
}

.service-button-2 {
  background-color: #5a67d8; /* Indigo */
}

.service-button-3 {
  background-color: #5a67d8; /* Indigo */
}

@media (min-width: 768px) {
  .service-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
