/* LandingPage.css */

.ctaok-button {
  display: inline-block;
  padding: 15px 25px;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: #f39c12;
  color: #fff;
  border-radius: 25px;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  animation: fadeInUp 1s ease-out 1.5s;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.ctaok-button:hover {
  background-color: #e67e22;
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(50, 50, 93, 0.16), 0 1px 3px rgba(0, 0, 0, 0.08);
}
