/* Home.css */

/* ... (your existing styles) */

.cta-button-fancy {
  background: linear-gradient(45deg, #2ecc71, #3498db);
  border: none;
  color: #fff;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  border-radius: 50px; /* Make it round */
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
}

.cta-button-fancy span {
  position: relative;
  z-index: 1;
}

.cta-button-fancy:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #2ecc71, #3498db);
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
  z-index: 0;
  transition: all 0.3s ease;
  transform-origin: 0 0;
  animation: tilt 2s infinite linear; /* Added animation */
}

@keyframes tilt {
  0%, 100% {
    transform: skewX(-15deg);
  }
  50% {
    transform: skewX(15deg);
  }
}

.cta-button-fancy:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.cta-button-fancy:hover:before {
  transform: scaleY(1.2) scaleX(1.5);
}
