/* Add this CSS to your stylesheet */
.fixed-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* Adjust the z-index as needed */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add a box-shadow for a nice effect */
    /* Additional styling for your fixed navbar, such as background color, text color, etc. */
  }
  /* Navbar.css */

/* ... (existing styles) */

.navbar {
  background-color: white;
  transition: background-color 0.3s ease-in-out;
}

.fixed-navbar {
  background-color: #ffffff; /* Add your desired background color when the navbar is fixed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow when fixed */
}

/* Add animation to the navigation links */
.navbar a {
  position: relative;
  overflow: hidden;
}

.navbar a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #3498db; /* Add your desired highlight color */
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-in-out;
}

.navbar a:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Add animation to the mobile menu icon */
.navbar button {
  transition: transform 0.3s ease-in-out;
}

.mobile-menu-open ul a {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.mobile-menu-open ul a:hover {
  background-color: #f9f9f9; /* Add a background color on hover for better visibility */
}

.mobile-menu-open button {
  transform: rotate(0); /* Reset the rotation on mobile menu close */
}

/* Navbar.css */
.navbar {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Add the same styles as before for the fixed navbar, if needed */
.fixed-navbar {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* ... (rest of your CSS) */
/* Navbar.css */
.navbar.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  /* Add any other styles you need for the fixed navbar */
}
