.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-link {
  margin: 0 20px;
  transform-origin: center;
  animation: rotate 10s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
