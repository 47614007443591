/* Special.css */

.special-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9; /* Light gray background color */
  }
  
  .card {
    width: 80%;
    margin: 20px 0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: left;
    transition: transform 0.3s ease-in-out;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .fancy-heading {
    color: #3498db;
    font-size: 1.8em;
    display: flex;
    align-items: center;
  }
  
  .fancy-heading .icon {
    margin-right: 10px;
    font-size: 1.5em;
  }
  
  ul {
    padding: 0;
    margin: 10px 0 0 20px;
  }
  
  li {
    list-style-type: disc;
  }
  